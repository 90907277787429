<template>
  <div class="w-full h-full">
    <split-pane
      split="vertical"
      :default-percent="20"
      class="w-full h-full">
      <div class="w-full h-full flex flex-col overflow-y-auto" slot="paneL">
        <v-list class="py-0">
          <v-subheader>Filter by Date</v-subheader>
          <template v-for="filter in removeDateFilters">
            <v-list-item dense :key="filter.key" link @click="() => {}">
              <v-list-item-title>
                {{ filter.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <div class="w-full h-full flex flex-col overflow-hidden" slot="paneR">
        <div class="flex flex-row items-center px-4">
          <div class="flex flex-row space-x-1 items-center pr-4" style="max-width: 40%">
            <span class="caption">Labels:</span>
            <label-list
              :labels="selectedLabels"
              @click-close="removeSelectedLabel"
              >
            </label-list>
            <label-selector-menu :selected-labels="selectedLabels"></label-selector-menu>
          </div>
          <v-spacer></v-spacer>
          <div class="flex flex-row space-x-1 items-center shrink" style="max-width: 40%">
            <span class="caption">Types:</span>
            <note-type-list
              :types="selectedTypes"
              @click-close="removeSelectedType"
              >
            </note-type-list>
            <note-type-selector-menu
              :selected-types="selectedTypes"
              >
            </note-type-selector-menu>
          </div>
        </div>
        <div class="py-4 flex flex-col overflow-y-auto" style="height: calc(100% - 40px);">
          <template v-if="viewMode == 'grid'">
            <notes-grid
              :notes="noteRecords"
              mode="trash"
              @restore="onRestoreNote"
              @alert-remove="onDeleteNote"
              @select="onTryEditNote"
              >
            </notes-grid>
          </template>
          <template v-else>
            <notes-list
              :notes="noteRecords"
              mode="trash"
              @restore="onRestoreNote"
              @alert-remove="onDeleteNote"
              @select="onTryEditNote"
              >
            </notes-list>
          </template>

          <div class="w-full flex items-center my-8">
            <v-btn
              v-if="hasMoreNotes"
              outlined
              class="mx-auto"
              @click="loadMoreNotes(false)"
              >
              Click to load more...
            </v-btn>
            <p v-else class="mx-auto">No more data to load</p>
          </div>
        </div>
      </div>
    </split-pane>
  </div>
</template>

<script>
import _ from 'lodash'
import NotesGrid from '@/components/NotesGrid.vue'
import NotesList from '@/components/NotesList.vue'
import SplitPane from '@/components/splitpane'
import LabelSelectorMenu from '@/components/LabelSelectorMenu'
import NoteTypeSelectorMenu from '@/components/NoteTypeSelectorMenu.vue'
import LabelList from '@/components/LabelList.vue'
import NoteTypeList from '@/components/NoteTypeList.vue'
import MakeLoadMoreMixin from '@/api/make-loadmore-mixin'

export default {
  name: 'TrashView',
  
  components: {
    SplitPane,
    NotesGrid,
    NotesList,
    LabelSelectorMenu,
    NoteTypeSelectorMenu,
    LabelList,
    NoteTypeList
  },
  
  mixins: [
    MakeLoadMoreMixin({ name: 'Note' }),
  ],

  data() {
    return {
      removeDateFilters: [
        { key: '0', title: 'Remove in last 3 hours', icon: '' },
        { key: '1', title: 'Remove Today', icon: '' },
        { key: '2', title: 'Remove Yesterday', icon: '' },
        { key: '3', title: 'Remove in Last 3 days', icon: '' },
        { key: '7', title: 'Remove in Last week', icon: '' }
      ],
      selectedRemoveDate: null,
      selectedLabels: [],
      selectedTypes: []
    }
  },

  computed: {
    viewMode() {
      return this.$store.state.notesViewMode
    },

    noteFilters() {
      let filters = []
      if (this.selectedLabels.length > 0) {
        filters.push({
          op: 'containedIn',
          key: 'labels',
          value: this.selectedLabels
        })
      }

      if (this.selectedTypes.length > 0) {
        let types = this.selectedTypes.map( t => t.key )
        filters.push({
          op: 'containedIn',
          key: 'type',
          value: types
        })
      }

      if (this.selectedRemoveDate) {
        // should we use 
        let days = _.toInteger(this.selectedRemoveDate)
        let queryDate = new Date()
        if (days == 0) {
          // add 3 hours
          queryDate = new Date(queryDate.getTime() + 180*60000);
        } else {
          queryDate.setDate(queryDate.getDate() + days)
        }
        filters.push({
          op: 'lessThan',
          key: 'deletedAt',
          value: queryDate
        })
      } else {
        filters.push({
          op: 'notEqualTo',
          key: 'deletedAt',
          value: null
        })
      }

      return filters
    }
  },

  methods: {
    removeSelectedLabel(label) {
      let index = _.findIndex(this.selectedLabels, (x) => x.id == label.id)
      if (index > -1) {
        this.selectedLabels.splice(index, 1)
      }
    },

    removeSelectedType(type) {
      let index = _.findIndex(this.selectedTypes, (x) => x.key == type.key)
      if (index > -1) {
        this.selectedTypes.splice(index, 1)
      }
    },

    async onRestoreNote(note) {
      try {
        let yes = await this.$confirm('Are you sure to restore the note? It will resotred under uncategoried.')
        if (yes) {
          await this.$Parse.Cloud.run('restoreNote', {
            note: note.id
          })
          await this.loadMoreNotes(true)
        }
      } catch(error) {
        console.error(error)
      }
    },

    async onDeleteNote(note) {
      try {
        if (!note) {
          return
        }
        let yes = await this.$confirm('Are you sure to delete the note permanently? It can not be recovered.')
        if (yes) {
          await note.destroy()
          await this.loadMoreNotes(true)
        }
      } catch(error) {
        console.error(error)
      }
    },

    async onTryEditNote(note) {
      try {
        let yes = await this.$confirm(
          'You have to restore the note from trash before you can edit. Restore it now?', {
            buttonTrueText: 'Restore it',
            buttonFalseText: 'Cancel'
          })
        if (yes) {
          await this.$Parse.Cloud.run('restoreNote', {
            note: note.id
          })
          await this.loadMoreNotes(true)
          this.$eventBus.$emit('edit.note', note)
        }
      } catch(error) {
        console.error(error)
      }
    }
  },

  mounted() {
    if (!this.$api.isAuthenticated()) {
      this.$router.push({ name: 'login', query: { ref: 'user-trash' } })
    }
  },
}
</script>